import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../../types';

/**
 * 내부가 흰색으로 칠해진 휴대폰 아이콘
 * @param props
 * @returns
 */
export function DeviceMobileSolid(props: IconCommonProps) {
  return (
    <IconContainer {...props} viewBox="0 0 36 36">
      <g id="device-mobile">
        <g id="Vector">
          <path
            d="M9 7.5C9 6.70435 9.31607 5.94129 9.87868 5.37868C10.4413 4.81607 11.2044 4.5 12 4.5H24C24.7956 4.5 25.5587 4.81607 26.1213 5.37868C26.6839 5.94129 27 6.70435 27 7.5V28.5C27 29.2956 26.6839 30.0587 26.1213 30.6213C25.5587 31.1839 24.7956 31.5 24 31.5H12C11.2044 31.5 10.4413 31.1839 9.87868 30.6213C9.31607 30.0587 9 29.2956 9 28.5V7.5Z"
            fill="white"
          />
          <path d="M16.5 6H19.5H16.5Z" fill="white" />
          <path d="M18 25.5V25.515V25.5Z" fill="white" />
          <path
            d="M16.5 6H19.5M18 25.5V25.515M9 7.5C9 6.70435 9.31607 5.94129 9.87868 5.37868C10.4413 4.81607 11.2044 4.5 12 4.5H24C24.7956 4.5 25.5587 4.81607 26.1213 5.37868C26.6839 5.94129 27 6.70435 27 7.5V28.5C27 29.2956 26.6839 30.0587 26.1213 30.6213C25.5587 31.1839 24.7956 31.5 24 31.5H12C11.2044 31.5 10.4413 31.1839 9.87868 30.6213C9.31607 30.0587 9 29.2956 9 28.5V7.5Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </IconContainer>
  );
}

export default DeviceMobileSolid;
