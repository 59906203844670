import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../../types';

/**
 * Strike 아이콘
 * @param props
 * @returns
 */
export function StrikeIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <path
        d="M5 12H19M16 6.50002C15.7732 6.05998 15.2536 5.67167 14.5265 5.39902C13.7994 5.12638 12.9084 4.98565 12 5.00002H11C10.0717 5.00002 9.1815 5.36877 8.52513 6.02515C7.86875 6.68153 7.5 7.57177 7.5 8.50002C7.5 9.42828 7.86875 10.3185 8.52513 10.9749C9.1815 11.6313 10.0717 12 11 12H13C13.9283 12 14.8185 12.3688 15.4749 13.0252C16.1313 13.6815 16.5 14.5718 16.5 15.5C16.5 16.4283 16.1313 17.3185 15.4749 17.9749C14.8185 18.6313 13.9283 19 13 19H11.5C10.5916 19.0144 9.70056 18.8737 8.9735 18.601C8.24644 18.3284 7.72675 17.9401 7.5 17.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconContainer>
  );
}

export default StrikeIcon;
