import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../../types';

/**
 * 새로고침 아이콘
 * @param props
 * @returns
 */
export function RefreshIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <path
        d="M16.023 9.348H21.015L17.834 6.165C16.8099 5.14087 15.5342 4.40439 14.1352 4.02961C12.7362 3.65482 11.2632 3.65493 9.86428 4.02992C8.46534 4.40492 7.18977 5.14159 6.16581 6.16587C5.14184 7.19015 4.40557 8.46595 4.03101 9.865M2.98501 19.644V14.652M2.98501 14.652H7.97701M2.98501 14.652L6.16501 17.835C7.18912 18.8591 8.4648 19.5956 9.8638 19.9704C11.2628 20.3452 12.7358 20.3451 14.1347 19.9701C15.5337 19.5951 16.8092 18.8584 17.8332 17.8341C18.8572 16.8099 19.5934 15.5341 19.968 14.135M21.015 4.356V9.346"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconContainer>
  );
}

export default RefreshIcon;
