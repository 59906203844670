import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../../types';

/**
 * ChevronUp 아이콘
 * @param props
 * @returns
 */
export function ChevronUpIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4697 7.71967C11.7626 7.42678 12.2374 7.42678 12.5303 7.71967L20.0303 15.2197C20.3232 15.5126 20.3232 15.9874 20.0303 16.2803C19.7374 16.5732 19.2626 16.5732 18.9697 16.2803L12 9.31066L5.03033 16.2803C4.73744 16.5732 4.26256 16.5732 3.96967 16.2803C3.67678 15.9874 3.67678 15.5126 3.96967 15.2197L11.4697 7.71967Z"
        fill="currentColor"
      />
    </IconContainer>
  );
}

export default ChevronUpIcon;
