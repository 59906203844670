import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../../types';

/**
 * 별표 solid 아이콘
 * @param props
 * @returns
 */
export function StarSolidIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <g id="star">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.993 1.25201C12.2785 1.25201 12.5392 1.41409 12.6655 1.67009L15.577 7.56935L22.0866 8.51277C22.3692 8.55374 22.6041 8.75171 22.6923 9.02337C22.7805 9.29503 22.7068 9.59322 22.5021 9.79244L17.7849 14.3842L18.8972 20.8682C18.9455 21.1497 18.8297 21.4342 18.5985 21.602C18.3674 21.7698 18.061 21.7917 17.8083 21.6585L11.9996 18.5976L6.177 21.6589C5.92431 21.7917 5.6181 21.7696 5.38714 21.6018C5.15618 21.434 5.04051 21.1496 5.08878 20.8682L6.20105 14.3842L1.48385 9.79244C1.27917 9.59322 1.20543 9.29503 1.29365 9.02337C1.38187 8.75171 1.61673 8.55374 1.89941 8.51277L8.40901 7.56935L11.3204 1.67009C11.4468 1.41409 11.7075 1.25201 11.993 1.25201Z"
          fill="currentColor"
        />
      </g>
    </IconContainer>
  );
}

export default StarSolidIcon;
