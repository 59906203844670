import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../../types';

/**
 * ListBullet 아이콘
 * @param props
 * @returns
 */
export function ListBulletIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 5.25C5.41421 5.25 5.75 5.58579 5.75 6V6.01C5.75 6.42421 5.41421 6.76 5 6.76C4.58579 6.76 4.25 6.42421 4.25 6.01V6C4.25 5.58579 4.58579 5.25 5 5.25ZM8.25 6C8.25 5.58579 8.58579 5.25 9 5.25H20C20.4142 5.25 20.75 5.58579 20.75 6C20.75 6.41421 20.4142 6.75 20 6.75H9C8.58579 6.75 8.25 6.41421 8.25 6ZM5 11.25C5.41421 11.25 5.75 11.5858 5.75 12V12.01C5.75 12.4242 5.41421 12.76 5 12.76C4.58579 12.76 4.25 12.4242 4.25 12.01V12C4.25 11.5858 4.58579 11.25 5 11.25ZM8.25 12C8.25 11.5858 8.58579 11.25 9 11.25H20C20.4142 11.25 20.75 11.5858 20.75 12C20.75 12.4142 20.4142 12.75 20 12.75H9C8.58579 12.75 8.25 12.4142 8.25 12ZM5 17.25C5.41421 17.25 5.75 17.5858 5.75 18V18.01C5.75 18.4242 5.41421 18.76 5 18.76C4.58579 18.76 4.25 18.4242 4.25 18.01V18C4.25 17.5858 4.58579 17.25 5 17.25ZM8.25 18C8.25 17.5858 8.58579 17.25 9 17.25H20C20.4142 17.25 20.75 17.5858 20.75 18C20.75 18.4142 20.4142 18.75 20 18.75H9C8.58579 18.75 8.25 18.4142 8.25 18Z"
        fill="currentColor"
      />
    </IconContainer>
  );
}

export default ListBulletIcon;
