import { ColorSetTypes, SizeTypes } from './types';

export function fixColorsetClass(colorset?: ColorSetTypes) {
  if (colorset === 'major') {
    return 'level1';
  }
  if (colorset === 'minor') {
    return 'level2';
  }

  return colorset;
}

export function fixSizeClass<T = SizeTypes>(size?: T) {
  if (size === 'small') {
    return 'sm';
  }
  if (size === 'medium') {
    return 'md';
  }
  if (size === 'large') {
    return 'lg';
  }
  if (size === 'none') {
    return undefined;
  }

  return size;
}
