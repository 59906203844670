import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../../types';

/**
 * EllipsisVertical 아이콘
 * @param props
 * @returns
 */
export function EllipsisHorizontalIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <path
        d="M4.83325 10.8333C5.14268 10.5238 5.56237 10.35 5.99998 10.35C6.43758 10.35 6.85727 10.5238 7.1667 10.8333C7.47614 11.1427 7.64998 11.5624 7.64998 12C7.64998 12.4376 7.47614 12.8573 7.1667 13.1667C6.85727 13.4762 6.43758 13.65 5.99998 13.65C5.56237 13.65 5.14268 13.4762 4.83325 13.1667C4.52381 12.8573 4.34998 12.4376 4.34998 12C4.34998 11.5624 4.52381 11.1427 4.83325 10.8333Z"
        fill="currentColor"
      />
      <path
        d="M10.8333 10.8333C11.1427 10.5238 11.5624 10.35 12 10.35C12.4376 10.35 12.8573 10.5238 13.1667 10.8333C13.4761 11.1427 13.65 11.5624 13.65 12C13.65 12.4376 13.4761 12.8573 13.1667 13.1667C12.8573 13.4762 12.4376 13.65 12 13.65C11.5624 13.65 11.1427 13.4762 10.8333 13.1667C10.5238 12.8573 10.35 12.4376 10.35 12C10.35 11.5624 10.5238 11.1427 10.8333 10.8333Z"
        fill="currentColor"
      />
      <path
        d="M16.8332 10.8333C17.1427 10.5238 17.5624 10.35 18 10.35C18.4376 10.35 18.8573 10.5238 19.1667 10.8333C19.4761 11.1427 19.65 11.5624 19.65 12C19.65 12.4376 19.4761 12.8573 19.1667 13.1667C18.8573 13.4762 18.4376 13.65 18 13.65C17.5624 13.65 17.1427 13.4762 16.8332 13.1667C16.5238 12.8573 16.35 12.4376 16.35 12C16.35 11.5624 16.5238 11.1427 16.8332 10.8333Z"
        fill="currentColor"
      />
    </IconContainer>
  );
}

export default EllipsisHorizontalIcon;
