import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../../types';

/**
 * Italic 아이콘
 * @param props
 * @returns
 */
export function Italic(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9827 4.25001H11C10.5858 4.25001 10.25 4.5858 10.25 5.00001C10.25 5.41422 10.5858 5.75001 11 5.75001H13.0057L9.43427 18.25H7C6.58579 18.25 6.25 18.5858 6.25 19C6.25 19.4142 6.58579 19.75 7 19.75H9.98343C9.99475 19.7503 10.0061 19.7503 10.0173 19.75H13C13.4142 19.75 13.75 19.4142 13.75 19C13.75 18.5858 13.4142 18.25 13 18.25H10.9943L14.5657 5.75001H17C17.4142 5.75001 17.75 5.41422 17.75 5.00001C17.75 4.5858 17.4142 4.25001 17 4.25001H14.0166C14.0052 4.24975 13.9939 4.24975 13.9827 4.25001Z"
        fill="currentColor"
      />
    </IconContainer>
  );
}

export default Italic;
