import type { PropsWithChildren } from 'react';

import * as Jotai from 'jotai';
import * as ToastPrimitives from '../../../shared/ui/toast';

import { toastStore } from '../model/store';
import ToastMessageContainer from './toast-message-container';

export interface Props extends ToastPrimitives.ProviderProps {}

export function ToastMessageProvider({
  children,
  direction = 'right',
  duration = 5000,
  usePortal = false,
  maxLength = 5,
  size = 'md',
  ...props
}: PropsWithChildren<Props>) {
  return (
    <Jotai.Provider store={toastStore}>
      <ToastPrimitives.Provider
        swipeDirection={direction}
        duration={duration}
        direction={direction}
        usePortal={usePortal}
        size={size}
        {...props}
      >
        <ToastMessageContainer maxLength={maxLength} size={size} />
        <ToastPrimitives.Viewport />
        {children}
      </ToastPrimitives.Provider>
    </Jotai.Provider>
  );
}

export default ToastMessageProvider;
