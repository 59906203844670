import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../../types';

/**
 * Lock (24x24) 아이콘
 * @param props
 * @returns
 */
export function LockIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props} viewBox="0 0 18 18">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.21577 2.77827C6.95419 2.03984 7.95571 1.625 9 1.625C10.0443 1.625 11.0458 2.03984 11.7842 2.77827C12.5227 3.51669 12.9375 4.51821 12.9375 5.5625V7.8125C13.5342 7.8125 14.1065 8.04955 14.5285 8.47151C14.9504 8.89347 15.1875 9.46576 15.1875 10.0625V15.125C15.1875 15.7217 14.9504 16.294 14.5285 16.716C14.1065 17.1379 13.5342 17.375 12.9375 17.375H5.0625C4.46576 17.375 3.89347 17.1379 3.47151 16.716C3.04955 16.294 2.8125 15.7217 2.8125 15.125V10.0625C2.8125 9.46576 3.04955 8.89347 3.47151 8.47151C3.89347 8.04955 4.46576 7.8125 5.0625 7.8125V5.5625C5.0625 4.51821 5.47734 3.51669 6.21577 2.77827ZM5.0625 8.9375C4.76413 8.9375 4.47798 9.05603 4.267 9.267C4.05603 9.47798 3.9375 9.76413 3.9375 10.0625V15.125C3.9375 15.4234 4.05603 15.7095 4.267 15.9205C4.47798 16.1315 4.76413 16.25 5.0625 16.25H12.9375C13.2359 16.25 13.522 16.1315 13.733 15.9205C13.944 15.7095 14.0625 15.4234 14.0625 15.125V10.0625C14.0625 9.76413 13.944 9.47798 13.733 9.267C13.522 9.05603 13.2359 8.9375 12.9375 8.9375H5.0625ZM11.8125 7.8125H6.1875V5.5625C6.1875 4.81658 6.48382 4.10121 7.01126 3.57376C7.53871 3.04632 8.25408 2.75 9 2.75C9.74592 2.75 10.4613 3.04632 10.9887 3.57376C11.5162 4.10121 11.8125 4.81658 11.8125 5.5625V7.8125Z"
        fill="currentColor"
      />
    </IconContainer>
  );
}

export default LockIcon;
