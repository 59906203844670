import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../../types';

/**
 * 인풋 텍스트 보임 표시 아이콘
 * @param props
 * @returns
 */
export function InputVisibleIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.64181 11.8166C5.7358 8.5252 8.84145 6.15255 12.4994 6.15255C16.1556 6.15255 19.2598 8.52276 20.3562 11.8129L20.3573 11.8162C20.363 11.833 20.3634 11.8519 20.357 11.871L20.356 11.874C19.2628 15.1652 16.1573 17.5379 12.4994 17.5379C8.84314 17.5379 5.73826 15.1678 4.64173 11.8779C4.63514 11.8581 4.63514 11.8367 4.64173 11.8169L4.64181 11.8166ZM12.4994 4.60001C8.15312 4.60001 4.46731 7.41964 3.16859 11.3267L3.16851 11.327C3.05613 11.6649 3.05615 12.0301 3.16859 12.368L3.1687 12.3684C4.4701 16.2735 8.15492 19.0904 12.4994 19.0904C16.8451 19.0904 20.5307 16.2714 21.8289 12.3648C21.9424 12.0266 21.9429 11.6609 21.8291 11.3221C20.5278 7.41703 16.8439 4.60001 12.4994 4.60001ZM11.2186 10.5644C11.5583 10.2247 12.019 10.0339 12.4994 10.0339C12.9797 10.0339 13.4405 10.2247 13.7801 10.5644C14.1198 10.9041 14.3107 11.3648 14.3107 11.8452C14.3107 12.3256 14.1198 12.7863 13.7801 13.126C13.4405 13.4657 12.9797 13.6565 12.4994 13.6565C12.019 13.6565 11.5583 13.4657 11.2186 13.126C10.8789 12.7863 10.6881 12.3256 10.6881 11.8452C10.6881 11.3648 10.8789 10.9041 11.2186 10.5644ZM12.4994 8.48137C11.6072 8.48137 10.7516 8.83577 10.1208 9.46662C9.48991 10.0975 9.13551 10.9531 9.13551 11.8452C9.13551 12.7374 9.48991 13.593 10.1208 14.2238C10.7516 14.8547 11.6072 15.2091 12.4994 15.2091C13.3915 15.2091 14.2471 14.8547 14.878 14.2238C15.5088 13.593 15.8632 12.7374 15.8632 11.8452C15.8632 10.9531 15.5088 10.0975 14.878 9.46662C14.2471 8.83577 13.3915 8.48137 12.4994 8.48137Z"
        fill="#444444"
      />
    </IconContainer>
  );
}

export default InputVisibleIcon;
