import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../../types';

/**
 * Bold 아이콘
 * @param props
 * @returns
 */
export function BoldIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25 5C6.25 4.58579 6.58579 4.25 7 4.25H13C14.1272 4.25 15.2082 4.69777 16.0052 5.4948C16.8022 6.29183 17.25 7.37283 17.25 8.5C17.25 9.62717 16.8022 10.7082 16.0052 11.5052C15.9497 11.5607 15.8929 11.6144 15.8348 11.6665C16.265 11.8724 16.6615 12.1511 17.0052 12.4948C17.8022 13.2918 18.25 14.3728 18.25 15.5C18.25 16.6272 17.8022 17.7082 17.0052 18.5052C16.2082 19.3022 15.1272 19.75 14 19.75H7C6.58579 19.75 6.25 19.4142 6.25 19V5ZM13 11.25C13.7293 11.25 14.4288 10.9603 14.9445 10.4445C15.4603 9.92882 15.75 9.22935 15.75 8.5C15.75 7.77065 15.4603 7.07118 14.9445 6.55546C14.4288 6.03973 13.7293 5.75 13 5.75H7.75V11.25H13ZM7.75 12.75H14C14.7293 12.75 15.4288 13.0397 15.9445 13.5555C16.4603 14.0712 16.75 14.7707 16.75 15.5C16.75 16.2293 16.4603 16.9288 15.9445 17.4445C15.4288 17.9603 14.7293 18.25 14 18.25H7.75V12.75Z"
        fill="currentColor"
      />
    </IconContainer>
  );
}

export default BoldIcon;
