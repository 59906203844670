import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../../types';

/**
 * Works 앱 아이콘
 * @param props
 * @returns
 */
export function CubeIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <mask id="path-1-inside-1_250_7643" fill="white">
        <rect x="3" y="3" width="8" height="8" rx="1" />
      </mask>
      <rect
        x="3"
        y="3"
        width="8"
        height="8"
        rx="1"
        stroke="currentColor"
        strokeWidth="3.2"
        mask="url(#path-1-inside-1_250_7643)"
      />
      <mask id="path-2-inside-2_250_7643" fill="white">
        <rect x="3" y="13" width="8" height="8" rx="1" />
      </mask>
      <rect
        x="3"
        y="13"
        width="8"
        height="8"
        rx="1"
        stroke="currentColor"
        strokeWidth="3.2"
        mask="url(#path-2-inside-2_250_7643)"
      />
      <mask id="path-3-inside-3_250_7643" fill="white">
        <rect x="13" y="3" width="8" height="8" rx="1" />
      </mask>
      <rect
        x="13"
        y="3"
        width="8"
        height="8"
        rx="1"
        stroke="currentColor"
        strokeWidth="3.2"
        mask="url(#path-3-inside-3_250_7643)"
      />
      <mask id="path-4-inside-4_250_7643" fill="white">
        <rect x="13" y="13" width="8" height="8" rx="1" />
      </mask>
      <rect
        x="13"
        y="13"
        width="8"
        height="8"
        rx="1"
        stroke="currentColor"
        strokeWidth="3.2"
        mask="url(#path-4-inside-4_250_7643)"
      />
    </IconContainer>
  );
}

export default CubeIcon;
