import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../../types';

/**
 * 체크가 그려진 방패모양 아이콘
 * @param props
 * @returns
 */
export function ShieldCheckIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props} viewBox="0 0 60 60">
      <g id="shield-check">
        <path
          id="Vector (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.6228 5.33465C29.394 4.60228 30.6035 4.60158 31.3756 5.33306C36.3934 10.0871 43.1661 12.9974 50.625 12.9974H51.005C51.8689 12.9974 52.6351 13.5521 52.9049 14.3727C53.9404 17.5217 54.5 20.885 54.5 24.3749C54.5 39.2895 44.3008 51.8149 30.4985 55.3668C30.1714 55.451 29.8283 55.4509 29.5012 55.3667C15.6993 51.8122 5.50001 39.2871 5.50001 24.3724H7.50001L5.50001 24.374C5.50001 24.3735 5.50001 24.3729 5.50001 24.3724C5.49744 20.9768 6.03577 17.6024 7.09476 14.3762C7.3675 13.5453 8.14847 12.9879 9.02292 13.0001C16.3064 13.1018 23.3408 10.3506 28.6228 5.33465ZM30.0013 9.46809C24.5291 14.093 17.6535 16.7435 10.4865 16.9848C9.83032 19.3897 9.49799 21.8737 9.50001 24.3708L9.50001 24.3724C9.50001 37.2376 18.176 48.0793 30.0002 51.3597C41.824 48.0816 50.5 37.2401 50.5 24.3749C50.5 21.8123 50.1561 19.3326 49.513 16.9785C42.0899 16.725 35.3056 13.9441 30.0013 9.46809ZM38.6625 22.7474C39.5613 23.3895 39.7695 24.6386 39.1275 25.5374L29.7525 38.6624C29.4108 39.1408 28.8759 39.4446 28.29 39.4931C27.7041 39.5416 27.1265 39.3298 26.7108 38.9141L21.0858 33.2891C20.3047 32.5081 20.3047 31.2417 21.0858 30.4607C21.8668 29.6796 23.1332 29.6796 23.9142 30.4607L27.8698 34.4163L35.8725 23.2124C36.5146 22.3136 37.7637 22.1054 38.6625 22.7474Z"
          fill="currentColor"
        />
      </g>
    </IconContainer>
  );
}

export default ShieldCheckIcon;
