import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../../types';

/**
 * 메일 앱 아이콘
 * @param props
 * @returns
 */
export function MailIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <path
        d="M4.6875 6.75L11.3596 11.5403C11.7449 11.8168 12.2551 11.8168 12.6404 11.5403L19.3125 6.75M5.25 19H18.75C19.9926 19 21 17.9553 21 16.6667V7.33333C21 6.04467 19.9926 5 18.75 5H5.25C4.00736 5 3 6.04467 3 7.33333V16.6667C3 17.9553 4.00736 19 5.25 19Z"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconContainer>
  );
}

export default MailIcon;
