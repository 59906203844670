import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../../types';

/**
 * 노트북 모양 아이콘
 * @param props
 * @returns
 */
export function LaptopIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 6.75C5.9337 6.75 5.87011 6.77634 5.82322 6.82322C5.77634 6.87011 5.75 6.9337 5.75 7V15C5.75 15.0663 5.77634 15.1299 5.82322 15.1768C5.87011 15.2237 5.9337 15.25 6 15.25H18C18.0663 15.25 18.1299 15.2237 18.1768 15.1768C18.2237 15.1299 18.25 15.0663 18.25 15V7C18.25 6.93369 18.2237 6.87011 18.1768 6.82322C18.1299 6.77634 18.0663 6.75 18 6.75H6ZM4.76256 5.76256C5.09075 5.43438 5.53587 5.25 6 5.25H18C18.4641 5.25 18.9092 5.43437 19.2374 5.76256C19.5656 6.09075 19.75 6.53587 19.75 7V15C19.75 15.4641 19.5656 15.9092 19.2374 16.2374C18.9092 16.5656 18.4641 16.75 18 16.75H6C5.53587 16.75 5.09075 16.5656 4.76256 16.2374C4.43437 15.9092 4.25 15.4641 4.25 15V7C4.25 6.53587 4.43437 6.09075 4.76256 5.76256ZM2.25 19C2.25 18.5858 2.58579 18.25 3 18.25H21C21.4142 18.25 21.75 18.5858 21.75 19C21.75 19.4142 21.4142 19.75 21 19.75H3C2.58579 19.75 2.25 19.4142 2.25 19Z"
        fill="currentColor"
      />
    </IconContainer>
  );
}

export default LaptopIcon;
