import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../../types';

/**
 * Minus 아이콘
 * @param props
 * @returns
 */
export function MinusIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <path
        d="M5 12H19"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
      />
    </IconContainer>
  );
}

export default MinusIcon;
