import type { IconCommonProps } from '../../types';
import { IconContainer } from '../IconContainer';

/**
 * XMarkBorder 아이콘
 * @param props
 * @returns
 */
export function XMarkBorder(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <path
        d="M15.1286 14.9488L12.3537 12.1739L12.0001 11.8204L11.6466 12.1739L8.87137 14.9491L8.78298 14.8608L11.5582 12.0855L11.9117 11.732L11.5582 11.3784L8.67622 8.49646L8.76461 8.40807L11.6466 11.29L12.0001 11.6436L12.3537 11.29L15.2353 8.40841L15.3237 8.49679L12.4421 11.3784L12.0885 11.732L12.4421 12.0855L15.217 14.8604L15.1286 14.9488ZM12 21.5C17.2467 21.5 21.5 17.2467 21.5 12C21.5 6.7533 17.2467 2.5 12 2.5C6.7533 2.5 2.5 6.7533 2.5 12C2.5 17.2467 6.7533 21.5 12 21.5Z"
        fill="currentColor"
        stroke="white"
      />
    </IconContainer>
  );
}

export default XMarkBorder;
