import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../../types';

/**
 * 햄버게 메뉴 아이콘
 * @param props
 * @returns
 */
export function HamburgerMenuIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.14844 9.06406C5.14844 8.56701 5.55138 8.16406 6.04844 8.16406H18.0765C18.5735 8.16406 18.9765 8.56701 18.9765 9.06406C18.9765 9.56112 18.5735 9.96406 18.0765 9.96406H6.04844C5.55138 9.96406 5.14844 9.56112 5.14844 9.06406ZM5.14844 15.8141C5.14844 15.317 5.55138 14.9141 6.04844 14.9141H18.0765C18.5735 14.9141 18.9765 15.317 18.9765 15.8141C18.9765 16.3111 18.5735 16.7141 18.0765 16.7141H6.04844C5.55138 16.7141 5.14844 16.3111 5.14844 15.8141Z"
        fill="#AAAAAA"
      />
    </IconContainer>
  );
}

export default HamburgerMenuIcon;
