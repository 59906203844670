import { useEffect } from 'react';
import * as Jotai from 'jotai';
import { queueAtom, toastItemListAtom } from '../model/atoms';
import ToastMessage from './toast-message';

export interface Props {
  maxLength?: number;
  size: 'sm' | 'md' | 'lg';
}
function ToastMessageContainer({ maxLength = 5, size = 'md' }: Props) {
  const [queue, setQueue] = Jotai.useAtom(queueAtom);
  const [toastItemList, setToastItemList] = Jotai.useAtom(toastItemListAtom);

  useEffect(() => {
    if (toastItemList.length < maxLength && queue.length > 0 && queue[0]) {
      const newToast = queue[0];
      setQueue((prevQueue) => prevQueue.slice(1));
      setToastItemList((prevDisplayQueue) => [...prevDisplayQueue, newToast]);
    }
  }, [maxLength, queue, toastItemList, setQueue, setToastItemList]);

  const handleOnOpenChange = (id: string, open: boolean) => {
    setToastItemList((prevList) => {
      const newQueue = prevList.map((toast) =>
        toast.id === id ? { ...toast, open } : toast,
      );
      return newQueue.filter((toast) => toast.open);
    });
  };

  return toastItemList.map((item) => (
    <ToastMessage
      key={item.id}
      type={item.type}
      description={item.contents}
      open={item.open}
      size={size}
      onOpenChange={(open) => handleOnOpenChange(item.id, open)}
    />
  ));
}

export default ToastMessageContainer;
