import React from 'react';
import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../../types';

/**
 * ArrowRightCircleIcon 아이콘
 * @param props
 * @returns
 */
export function ArrowRightCircleIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <circle cx="12" cy="12" r="11.5" fill="white" stroke="currentColor" />
      <path
        d="M10.5 8.25L14.25 12L10.5 15.75"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconContainer>
  );
}

export default ArrowRightCircleIcon;
