import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../../types';

/**
 * 용량 추가 아이콘
 * @param props
 * @returns
 */
export function AddStorageIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <path
        d="M13.1864 17.3562C15.4941 17.3562 17.365 15.4853 17.365 13.1776C17.365 10.8698 15.4941 8.99902 13.1864 8.99902C10.8786 8.99902 9.00781 10.8698 9.00781 13.1776C9.00781 15.4853 10.8786 17.3562 13.1864 17.3562Z"
        stroke="currentColor"
        strokeWidth="1.28571"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5742 13.1777H14.7885"
        stroke="currentColor"
        strokeWidth="1.28571"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.1836 14.7852V11.5709"
        stroke="currentColor"
        strokeWidth="1.28571"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.71987 6.09401C11.6253 6.09401 14.7913 4.87366 14.7913 3.36829C14.7913 1.86293 11.6253 0.642578 7.71987 0.642578C3.81442 0.642578 0.648438 1.86293 0.648438 3.36829C0.648438 4.87366 3.81442 6.09401 7.71987 6.09401Z"
        stroke="currentColor"
        strokeWidth="1.28571"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.7891 5.78531V3.36816"
        stroke="currentColor"
        strokeWidth="1.28571"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.648438 3.36816V12.0596C0.648438 13.2553 2.62844 14.2581 5.39272 14.631"
        stroke="currentColor"
        strokeWidth="1.28571"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.77844 10.2853C2.82129 10.0024 0.648438 8.99958 0.648438 7.71387"
        stroke="currentColor"
        strokeWidth="1.28571"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconContainer>
  );
}

export default AddStorageIcon;
