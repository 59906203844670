import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../../types';

/**
 * list checked 아이콘
 * @param props
 * @returns
 */
export function LinkIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props} viewBox="0 0 20 20">
      <g>
        <g>
          <path
            d="M10.01 4.97983L9.63966 5.40852C9.41402 5.66973 9.01935 5.69858 8.75813 5.47293C8.49691 5.24729 8.46807 4.85262 8.69371 4.5914L9.07955 4.14473C9.08949 4.13322 9.09985 4.12208 9.11061 4.11132C10.0093 3.21272 11.2282 2.70795 12.4991 2.70804C13.77 2.70813 14.9888 3.21308 15.8874 4.1118C16.786 5.01052 17.2908 6.2294 17.2907 7.5003C17.2906 8.7712 16.7857 9.99 15.8869 10.8886C15.8766 10.8989 15.866 10.9088 15.855 10.9184L15.41 11.3051C15.1494 11.5315 14.7546 11.5038 14.5282 11.2432C14.3018 10.9827 14.3295 10.5879 14.5901 10.3615L15.0179 9.98978C15.6729 9.32704 16.0406 8.43259 16.0407 7.50021C16.0408 6.56083 15.6677 5.65991 15.0035 4.99562C14.3393 4.33133 13.4384 3.9581 12.499 3.95804C11.5669 3.95797 10.6727 4.32529 10.01 4.97983Z"
            fill="currentColor"
          />
          <path
            d="M12.942 7.05807C13.186 7.30215 13.186 7.69787 12.942 7.94195L7.94196 12.942C7.69788 13.186 7.30216 13.186 7.05808 12.942C6.814 12.6979 6.814 12.3021 7.05808 12.0581L12.0581 7.05807C12.3022 6.81399 12.6979 6.81399 12.942 7.05807Z"
            fill="currentColor"
          />
          <path
            d="M5.4684 8.75284C5.69696 9.01151 5.67254 9.40648 5.41388 9.63504L4.99088 10.0088C4.66558 10.3335 4.40702 10.7189 4.22988 11.1432C4.05058 11.5726 3.95825 12.0334 3.95825 12.4988C3.95825 12.9641 4.05058 13.4249 4.22988 13.8543C4.40918 14.2838 4.6719 14.6734 5.00281 15.0006C5.67644 15.6667 6.5856 16.0403 7.53295 16.0403C8.4651 16.0403 9.36024 15.6786 10.0304 15.0325L10.3318 14.6271C10.5377 14.3501 10.9293 14.2925 11.2063 14.4984C11.4833 14.7044 11.5409 15.0959 11.3349 15.3729L11.0041 15.8179C10.9852 15.8433 10.9645 15.8672 10.942 15.8894C10.0344 16.7869 8.80941 17.2903 7.53295 17.2903C6.25651 17.2903 5.03155 16.787 4.12393 15.8894C3.67545 15.446 3.31938 14.9179 3.07638 14.3359C2.83338 13.7539 2.70825 13.1295 2.70825 12.4988C2.70825 11.868 2.83338 11.2436 3.07638 10.6616C3.31939 10.0796 3.67544 9.55154 4.12393 9.10808C4.13224 9.09987 4.14077 9.09189 4.14953 9.08415L4.5862 8.69832C4.84487 8.46976 5.23984 8.49417 5.4684 8.75284Z"
            fill="currentColor"
          />
        </g>
      </g>
    </IconContainer>
  );
}

export default LinkIcon;
