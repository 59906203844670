import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../../types';

/**
 * ChevronRight 아이콘
 * @param props
 * @returns
 */
export function ChevronRightIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.61361 3.86358C7.96508 3.51211 8.53493 3.51211 8.8864 3.86358L16.3864 11.3636C16.7379 11.7151 16.7379 12.2849 16.3864 12.6364L8.8864 20.1364C8.53493 20.4878 7.96508 20.4878 7.61361 20.1364C7.26214 19.7849 7.26214 19.2151 7.61361 18.8636L14.4772 12L7.61361 5.13637C7.26214 4.7849 7.26214 4.21505 7.61361 3.86358Z"
        fill="currentColor"
      />
    </IconContainer>
  );
}

export default ChevronRightIcon;
