import { clsx } from 'clsx';
import type { PropsWithChildren } from 'react';
import type { IconCommonProps } from '../types';

export interface Props extends IconCommonProps {
  viewBox?: string;
}

export function IconContainer({
  size = 24,
  viewBox = '0 0 24 24',
  children,
  className,
  rotate,
}: PropsWithChildren<Props>) {
  const style = { transform: '' };
  if (rotate) {
    style.transform = `rotate(${rotate}deg)`;
  }
  return (
    <i
      className={clsx(
        'inline-flex justify-center items-center grow-0 shrink-0 basis-auto',
        className,
      )}
      style={style}
      data-size={size}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={viewBox}
        fill="none"
        width={size}
        height={size}
      >
        {children}
      </svg>
    </i>
  );
}

export default IconContainer;
