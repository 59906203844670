import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../../types';

/**
 * UserMinus 아이콘
 * @param props
 * @returns
 */
export function UserMinusIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <path
        d="M21.9995 10.5H15.9995M13.7495 6.375C13.7495 8.23896 12.2385 9.75 10.3745 9.75C8.51055 9.75 6.99951 8.23896 6.99951 6.375C6.99951 4.51104 8.51055 3 10.3745 3C12.2385 3 13.7495 4.51104 13.7495 6.375ZM4.00043 19.2343C3.99982 19.198 3.99951 19.1615 3.99951 19.125C3.99951 15.6042 6.8537 12.75 10.3745 12.75C13.8953 12.75 16.7495 15.6042 16.7495 19.125V19.1276C16.7495 19.1632 16.7492 19.1988 16.7486 19.2343C14.8869 20.3552 12.706 21 10.3745 21C8.04304 21 5.86215 20.3552 4.00043 19.2343Z"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconContainer>
  );
}

export default UserMinusIcon;
