import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../../types';

/**
 * 도움말 아이콘
 * @param props
 * @returns
 */
export function QuestionCircleIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.90001C9.85172 3.90001 7.79146 4.7534 6.27241 6.27244C4.75337 7.79149 3.89998 9.85175 3.89998 12C3.89998 13.0637 4.10949 14.117 4.51655 15.0997C4.92361 16.0825 5.52026 16.9754 6.27241 17.7276C7.02457 18.4797 7.9175 19.0764 8.90024 19.4834C9.88298 19.8905 10.9363 20.1 12 20.1C13.0637 20.1 14.117 19.8905 15.0997 19.4834C16.0824 19.0764 16.9754 18.4797 17.7275 17.7276C18.4797 16.9754 19.0763 16.0825 19.4834 15.0997C19.8905 14.117 20.1 13.0637 20.1 12C20.1 9.85175 19.2466 7.79149 17.7275 6.27244C16.2085 4.7534 14.1482 3.90001 12 3.90001ZM4.99962 4.99965C6.85623 3.14304 9.37433 2.10001 12 2.10001C14.6256 2.10001 17.1437 3.14304 19.0003 4.99965C20.8569 6.85626 21.9 9.37436 21.9 12C21.9 13.3001 21.6439 14.5874 21.1464 15.7886C20.6489 16.9897 19.9196 18.0811 19.0003 19.0004C18.081 19.9197 16.9897 20.6489 15.7885 21.1464C14.5874 21.6439 13.3001 21.9 12 21.9C10.6999 21.9 9.41253 21.6439 8.21141 21.1464C7.01029 20.6489 5.91892 19.9197 4.99962 19.0004C4.08032 18.0811 3.35109 16.9897 2.85357 15.7886C2.35605 14.5874 2.09998 13.3001 2.09998 12C2.09998 9.37436 3.14301 6.85626 4.99962 4.99965ZM14.7138 6.84179C13.2034 5.51974 10.7966 5.51974 9.2862 6.84179C8.91218 7.16918 8.87438 7.73777 9.20176 8.11178C9.52915 8.4858 10.0977 8.5236 10.4718 8.19622C11.3034 7.46827 12.6966 7.46827 13.5282 8.19622L13.5285 8.19647C14.2905 8.86288 14.2905 9.88713 13.5285 10.5535L13.5285 10.5535L13.5257 10.556C13.389 10.6766 13.2316 10.7794 13.0593 10.8627L13.0585 10.8631C12.2299 11.2646 11.101 12.1318 11.101 13.5V14.25C11.101 14.7471 11.5039 15.15 12.001 15.15C12.498 15.15 12.901 14.7471 12.901 14.25V13.5C12.901 13.2123 13.1819 12.8036 13.843 12.4831C14.15 12.3347 14.4461 12.144 14.7148 11.9073C16.2954 10.5237 16.295 8.22502 14.7138 6.84179ZM11.1 17.25C11.1 16.7529 11.5029 16.35 12 16.35H12.008C12.505 16.35 12.908 16.7529 12.908 17.25V17.258C12.908 17.7551 12.505 18.158 12.008 18.158H12C11.5029 18.158 11.1 17.7551 11.1 17.258V17.25Z"
        fill="currentColor"
      />
    </IconContainer>
  );
}

export default QuestionCircleIcon;
