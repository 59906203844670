import React from 'react';
import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../../types';

/**
 * ArrowLeft 아이콘
 * @param props
 * @returns
 */
export function ArrowLeftIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <path
        d="M19.5 12L4.5 12M4.5 12L11.25 18.75M4.5 12L11.25 5.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </IconContainer>
  );
}

export default ArrowLeftIcon;
