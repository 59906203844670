import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../../types';

/**
 * 검색 아이콘
 * @param props
 * @returns
 */
export function SearchIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5596 4.5596C6.13496 2.98424 8.2716 2.09921 10.4995 2.09921C12.7274 2.09921 14.864 2.98424 16.4394 4.5596C18.0147 6.13496 18.8998 8.2716 18.8998 10.4995C18.8998 12.4253 18.2385 14.283 17.0416 15.7688L21.6364 20.3636C21.9879 20.7151 21.9879 21.2849 21.6364 21.6364C21.2849 21.9879 20.7151 21.9879 20.3636 21.6364L15.7688 17.0416C14.283 18.2385 12.4253 18.8998 10.4995 18.8998C8.2716 18.8998 6.13496 18.0147 4.5596 16.4394C2.98424 14.864 2.09921 12.7274 2.09921 10.4995C2.09921 8.2716 2.98424 6.13496 4.5596 4.5596ZM10.4995 3.89921C8.74899 3.89921 7.07018 4.5946 5.83239 5.83239C4.5946 7.07018 3.89921 8.74899 3.89921 10.4995C3.89921 12.25 4.5946 13.9288 5.83239 15.1666C7.07018 16.4044 8.74899 17.0998 10.4995 17.0998C12.25 17.0998 13.9288 16.4044 15.1666 15.1666C16.4044 13.9288 17.0998 12.25 17.0998 10.4995C17.0998 8.74899 16.4044 7.07018 15.1666 5.83239C13.9288 4.5946 12.25 3.89921 10.4995 3.89921Z"
        fill="currentColor"
      />
    </IconContainer>
  );
}

export default SearchIcon;
