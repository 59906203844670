import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../../types';

export function OrgChartIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <circle
        cx="12.0001"
        cy="6.70001"
        r="2.00001"
        stroke="currentColor"
        strokeWidth="1.4"
      />
      <circle
        cx="5.70001"
        cy="17.7"
        r="2.00001"
        stroke="currentColor"
        strokeWidth="1.4"
      />
      <circle
        cx="12.0001"
        cy="17.7"
        r="2.00001"
        stroke="currentColor"
        strokeWidth="1.4"
      />
      <circle
        cx="18.3001"
        cy="17.7"
        r="2.00001"
        stroke="currentColor"
        strokeWidth="1.4"
      />
      <path
        d="M6 16V12.8571C6 12.3838 6.38376 12 6.85715 12H17.1429C17.6162 12 18 12.3838 18 12.8571V16"
        stroke="currentColor"
        strokeWidth="1.6"
      />
      <path d="M12 8.09286V15.7429" stroke="currentColor" strokeWidth="1.6" />
    </IconContainer>
  );
}

export default OrgChartIcon;
