import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../../types';

/**
 * ChevronDown 아이콘
 * @param props
 * @returns
 */
export function ChevronDownIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.86361 7.61358C4.21508 7.26211 4.78493 7.26211 5.1364 7.61358L12 14.4772L18.8636 7.61358C19.2151 7.26211 19.7849 7.26211 20.1364 7.61358C20.4879 7.96505 20.4879 8.5349 20.1364 8.88637L12.6364 16.3864C12.2849 16.7378 11.7151 16.7378 11.3636 16.3864L3.86361 8.88637C3.51214 8.5349 3.51214 7.96505 3.86361 7.61358Z"
        fill="currentColor"
      />
    </IconContainer>
  );
}

export default ChevronDownIcon;
