import { IconCommonProps } from '../../types';
import IconContainer from '../IconContainer';

export function ImageSelectIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.580697 18.2554C0.687452 18.4238 0.807263 18.583 0.938688 18.7318C1.62579 19.5096 2.63062 20 3.75 20H16.25C18.3211 20 20 18.3211 20 16.25V3.75C20 1.67893 18.3211 0 16.25 0H3.75C1.67893 0 0 1.67893 0 3.75V16.25C0 16.9876 0.212939 17.6754 0.580697 18.2554ZM1.46627 16.9445C1.39953 16.7247 1.36364 16.4916 1.36364 16.25V3.75C1.36364 2.43205 2.43205 1.36364 3.75 1.36364H16.25C17.568 1.36364 18.6364 2.43205 18.6364 3.75V13.2971L14.8571 9.51788C14.5909 9.25162 14.1591 9.25162 13.8929 9.51788L8.75 14.6608L6.73212 12.6429C6.46585 12.3766 6.03415 12.3766 5.76788 12.6429L1.46627 16.9445ZM18.6364 16.25V15.2256L14.375 10.9642L9.23212 16.1071C8.96585 16.3734 8.53415 16.3734 8.26788 16.1071L6.25 14.0892L2.2407 18.0985C2.65194 18.4347 3.17742 18.6364 3.75 18.6364H16.25C17.568 18.6364 18.6364 17.568 18.6364 16.25ZM5.67993 9.08939C7.33679 9.08939 8.67993 7.74624 8.67993 6.08939C8.67993 4.43253 7.33679 3.08939 5.67993 3.08939C4.02308 3.08939 2.67993 4.43253 2.67993 6.08939C2.67993 7.74624 4.02308 9.08939 5.67993 9.08939ZM7.3466 6.08939C7.3466 7.00986 6.60041 7.75605 5.67993 7.75605C4.75946 7.75605 4.01327 7.00986 4.01327 6.08939C4.01327 5.16891 4.75946 4.42272 5.67993 4.42272C6.60041 4.42272 7.3466 5.16891 7.3466 6.08939Z"
          fill="#AAAAAA"
        ></path>
      </svg>
    </IconContainer>
  );
}

export default ImageSelectIcon;
