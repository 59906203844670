import { ReactElement } from 'react';
import { clsx } from 'clsx';
import { InfoIcon, ExclamationCircleIcon } from '@dop-ui/icons/react/dop/24';
import * as ToastPrimitives from '../../../shared/ui/toast';
import { getToastStyles } from '../lib/utils';

import type { ToastTypes } from '../types';

export interface Props {
  type: ToastTypes;
  description: string | ReactElement;
  open: boolean;
  size: 'sm' | 'md' | 'lg';
  onOpenChange: (open: boolean) => void;
}

function ToastMessage({ type, open, onOpenChange, description, size }: Props) {
  const iconMap = {
    message: null,
    info: <InfoIcon className="mr-3" />,
    warning: <ExclamationCircleIcon className="mr-3" />,
    alert: <ExclamationCircleIcon className="mr-3" />,
  };

  const sizeClasses = {
    sm: 'max-w-[200px] min-w-[200px]',
    md: 'max-w-[400px] min-w-[400px]',
    lg: 'max-w-[600px] min-w-[600px]',
  };

  return (
    <ToastPrimitives.Root
      className={clsx(
        'text-white p-4 rounded shadow-lg flex items-center',
        getToastStyles(type),
        sizeClasses[size],
      )}
      open={open}
      onOpenChange={onOpenChange}
    >
      {iconMap[type]}
      {description}
    </ToastPrimitives.Root>
  );
}

export default ToastMessage;
